import './App.css';
import Main from './Components/Main/main';
import { Nav } from './Components/Nav/Nav';

function App() {
  return (
    <div className="App">
      <Nav />
      <Main />
    </div>
  );
}

export default App;
